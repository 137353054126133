div.HelloOfferBox {
    background: white;
    margin-left: 20px;
    margin-right: 20px;
    text-align: left;
}
div.HelloOfferBox .box {
	font-size: 22px;
	line-height: 40px;
}
@media (min-width: 800px) {
	div.HelloOfferBox .box {
		display: flex;
	}
	div.HelloOfferBox .box > div {
		width: 100%;
	}
}
@media (max-width: 800px) {
	div.HelloOfferBox .box {
		flex-direction: column;
		text-align: center;
		padding-left: auto;
		padding-right: auto;
	}
}


div.HelloOfferBox .box > div {
    color: black;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 12px;
	padding: 30px 40px 0px 40px;
	margin: 10px;
	
	display: flex;
	flex-direction: column;
}
div.HelloOfferBox .box div.chooseButton { 
	padding: 5px 15px;
	margin: 0 auto;
	padding-top: auto;
	text-align: center;
	width: 270px;
	font-size: 24px;
	line-height: 45px;
	background: #0BC0EF;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 12px;
}
div.HelloOfferBox .box h3 {
	color: #0BC0EF;
	font-size: 36px;
	text-decoration: underline;
	line-height: 51px;
}
div.HelloOfferBox .box .sideNote {
	text-align: right;
	padding-top: 30px;
}
div.HelloOfferBox .box .sideNote a {
	color: white;
	font-size: 31px;
	line-height: 51px;
}
div.HelloOfferBox .box .dummy {
	flex-grow: 1;
}

div.HelloOfferBox {
    li {
        display: inline;
        text-align: center;
        font-size: 18px;
    }

    ul {
        padding-left: 0px;
        margin-bottom: 0px;
    }

    span {
        text-align: right;
        right: 0px;
        padding-bottom: 30px;

        a:visited {
            color: black;
        }
    }
}