span.dropdownCheckboxList {
  position: relative;
}

span.dropdownCheckboxList ul {
  position: absolute;
  margin: 0;
  top: 2em;
  left: 0;
  padding: 8px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1;
  max-height: 300px;
  display: inline-block;
  overflow-y: auto;
  text-align: left;
}

span.dropdownCheckboxList li {
  list-style: none;
  margin-bottom: 8px;
  white-space: nowrap;
}

span.pagination select {
  vertical-align: 0px;
}
span.pagination input {
  vertical-align: 1px;
}
