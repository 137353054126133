.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button.small {
    padding: 3px 8px 4px 8px;
}

.Selectable-element:hover {
    margin: -2px;
    margin-left: 3px !important;
    margin-right: 3px !important;
    border: 2px solid black;
    box-shadow: 0 0 10px rgba(52, 152, 219, 0.5);
}


:root {
	--asecla-font-text-input: var(--asecla-font-standard);
	--asecla-color-title-background: #0061B7;
	--asecla-border-standard: 1px solid #F4F4F4;
	--asecla-color-text-hint: #A9A9A9;
	--asecla-color-list-background: #F4F4F4;
	--asecla-color-list-hover-background: #0086FF;
	--asecla-color-list-hover-text: white;
}

.titlebar {
	padding: 0.5em 1em 0.5em 1em;
	font-size: 1rem;
	background-color: var(--asecla-color-title-background);
	color: white;
	text-align: left;
	font-size: 125%;
	font-weight: bold;
	letter-spacing: 0.1em;
}
button .icon, .btn .icon, .titlebar .icon {
	filter: invert(1);
}
.titlebar a.close, .titlebar a.close:hover, .titlebar a.close:visited {
	float: right;
	cursor: pointer;
	color: white;
	text-decoration: none;
}

h4.asecla-heading,
h3.asecla-heading {
    text-align: left;
}

hr {
    border: 1px solid #ddd;
}

body.asecla-page header.asecla-commons-header-container {
    margin-bottom: 0px;
}