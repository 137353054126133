div.bigTableHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

div.bigTableHeader > span:first-child {
    margin-left: 0;
}

div.bigTableHeader > span:last-child {
    margin-right: 0;
}
