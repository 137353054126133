
.tooltip-outer {
	position: relative;
	display: inline-block;
	cursor: help;
}

.tooltip-outer .tooltip-inner {
	text-align: justify;
	font-size: 80%;
	visibility: hidden;
	max-width: 50vw;
	min-width: 34em;
	background-color: black;
	color: #fff;
	border-radius: 6px;
	padding: 1em;
	position: absolute;
	z-index: 100;
	top: 100%;
	right: 0%;
	margin-left: -30em;
	text-transform: none;
    white-space: normal;

	opacity: 0;
	transition: opacity 1s;
}

.tooltip-outer:hover .tooltip-inner {
	visibility: visible;
	opacity: 1;
}


.hint-icon {
	padding: 0.6em;
	margin-left: 0.5em;
}
.hint {
	color: var(--asecla-color-text-hint);
}